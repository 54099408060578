import React, { Component } from 'react';
import { Link } from 'gatsby';
import Tag from 'models/Tag';
import RouteUtils from 'utils/RouteUtils';
import GenericUtils from 'utils/GenericUtils';

type Props = {
  categories: Tag[];

  dense?: boolean;
  className?: string;
};

export default class CategoryList extends Component<Props> {

  render() {
    return (
      <div className={GenericUtils.classNames('category-list', this.props.className)}>
        {this.props.categories.map(category => {

          return (
            <Link
              key={category.name}
              to={RouteUtils.generatePathToCategory(category.name!)}
              className={this.props.dense ? 'category-list__item category-list__item--dense' : 'category-list__item'}
            >
              <div className="category-list__item__container">
                <div>
                  <h2 className="category-list__item__name">{category.name} ({category.count})</h2>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    );
  }

}
