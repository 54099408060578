import * as React from 'react';
import { graphql } from 'gatsby';
import Master from 'layouts/Master';
import Tag from 'models/Tag';
import Model from 'models/Model';
import PageUtils from 'utils/PageUtils';
import CategoryList from 'components/CategoryList';

type Data = {
  pageData: {
    camGirls: Model[];
    tags: Tag[];
  };
};

type Props = {
  data: Data;
}

type State = {
  categories: Tag[];
};

export default class IndexPage extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    const categories: Tag[] = [];
    props.data.pageData.tags.forEach(tag => {
      const repCamGirl = props.data.pageData.camGirls.find(model => (model.tags || []).includes(tag.name!));
      if (repCamGirl) {
        categories.push({
          name: tag.name,
          count: tag.count,
          representativeCamGirl: repCamGirl,
        });
      }
    });

    this.state = {
      categories,
    };
  }

  render() {
    return (
      <Master
        title={PageUtils.generateTitle('Categories')}
        description="List of categories of our big tits camgirls: from ebony to latina, from skinny to curvy, from teen to mature. We've got you covered."
      >

        <div className="container">
          <h1 className="page__title categories-page__title">
            Categories
          </h1>

          <CategoryList categories={this.state.categories} />
        </div>
      </Master>
    );
  }
}

export const pageQuery = graphql`
  {
    pageData: allCamGirl {
      camGirls: nodes {
        username
        id
        tags
      }
      tags: group(field: tags) {
          name: fieldValue
          count: totalCount
        }
    }
  }
`;
